module.exports = [
  {
    'api': 'https://apielogicagente.seplag.al.gov.br/',
    // 'api': 'https://elogicarhapi.elogica.info/api/',
    //"api": "https://genteapi.elogica.info:444/api/",
    //"homolog":"http://desenv.api.elogicarh.com/api/"
    'homolog': 'http://homologaapi.ayugate.com/api/',
    // 'localhost': 'https://defensoriaapi.elogica.info:444/api/',
    'localhost': 'http://localhost/EloWebApi/api/',
    //"localhost":"http://localhost:38524/api/"
  },
];
